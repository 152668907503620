export const environment = {
    production: true,
    logErrorInConsole: true,
    environmentName: "test",
    version: require("../../package.json").version,
    httpRequestTimeoutSeconds: 120,
    utcDateFormat: "yyyy-MM-dd HH:mm:ss",
    oauthBaseAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/auth/",
    usersAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/users/",
    rolesAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/roles/",
    organizationsAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/organizations/",
    profileAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/profile/",
    resetPasswordAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/resetpassword/",
    languagesAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/languages/",
    smtpMailAccountsAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/mailaccounts/",
    mailtemplatesAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/mailtemplates/",
    paypalConfigAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/paypalconfig/",
    paypalBillingPlansAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/paypalbillingplans/",
    paypalBillingAgreementsAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/paypalbillingagreements/",
    systemLogMessagesAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/systemlogs/",
    systemJobPerformancesAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/systemjobs/",
    systemErrorsAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/systemerrors/",
    registrationConfigAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/registrationconfig/",
    linkedInConfigAPI: "https://pluspulso-connectionmate-api-oepbt672ya-ey.a.run.app/api/v1/linkedinconfig/"
};
